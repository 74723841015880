$divider: rgba(246, 246, 247, 1);

.analyticWrapper {
  [class~="Polaris-Layout__Section--secondary"] {
    flex: 1 1;
    max-width: 34rem;
    min-width: 20rem;
  }
}

.underTableBar {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0.5rem 0;

  }
  .filters {
    display: flex;
    flex-grow: 1;
    margin-right: 1rem;
  }

  [class~='Polaris-Select'] {
    min-width: 18rem;
    margin-right: 1rem;
  }

  [class~='Polaris-SkeletonDisplayText__DisplayText']{
    width: 100%;
  }

  [class~='Polaris-SkeletonDisplayText__DisplayText'] ~ [class~='Polaris-SkeletonDisplayText__DisplayText'] {
    margin-left: 1rem;
  }
}