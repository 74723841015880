.modalWrapper {
 [class~='Polaris-Modal-Section'] {
   padding-bottom: 0;
 }
}

.cardTitle {
  display: flex;
  [class~="Polaris-Icon"]{
    display: inline-block;
    margin: 0;
    margin-right: 0.7rem;
  }
  [class~="Polaris-Heading"]{
    display: inline-block;
  }
}

.cardsLine{
  margin-bottom: 1.8rem;
  display: flex;

  [class~="Polaris-Card"] + [class~="Polaris-Card"]{
    margin-top: 0;
    margin-left: 1.8rem;
  }

  & > * {
    flex-basis: 50%;
  }

  &__purpleIcon * {
    fill: #6138FE;
  }

  &__greenIcon * {
    fill: #15B158;
  }
}

.img {
  display: block;
  width: 100%;
}