.tableFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  & [aria-label="Pagination"]{
    margin-right: 1rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  [class~='Polaris-SkeletonDisplayText__DisplayText']{
    width: 100%;
  }

  [class~='Polaris-Icon']{
    svg {
      display: none;
    }
  }

  [class~="Polaris-ButtonGroup"] > *:nth-last-child(1){
    [class ~="Polaris-Icon"] {
      background: url("./arrowRightDark.svg");
    }

    [class ~="Polaris-Button--disabled"]{
      [class ~="Polaris-Icon"] {
        background: url("./arrowRightLight.svg");
      }
    }
  }

  [class~="Polaris-ButtonGroup"] > *:nth-child(1){
    [class ~="Polaris-Icon"] {
      background: url("./arrowLeftDark.svg");
    }

    [class ~="Polaris-Button--disabled"]{
      [class ~="Polaris-Icon"] {
        background: url("./arrowLeftLight.svg");
      }
    }
  }
}
