.topBar {
  padding: 1.8rem;
  margin-bottom: 3.5rem;
  background-color: #202123;

  font-size: 1.8rem;
  color:white;
  display: flex;

  .logo {
    margin-right: 2rem;
  }

  .brands {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
    & > * {
      margin-left: 0.7rem;
    }
  }
}

.onboarding {
  background-color: white;
  padding: 3rem 10rem;
  display: flex;
  align-items: center;

  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  .title {
    font-size: 3rem;
    line-height: normal;
  }
  .subtitle {
    font-size: 2rem;
  }
}