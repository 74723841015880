.nameContainer {
  display: flex;
  align-items: center;

  & [class~="Polaris-Icon"] {
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }

  & svg {
    fill: #1458EA;
    width: 1rem;
    height: 1rem;
  }
}

.flowStatusContainer {
  display: flex;

  & [class~="Polaris-Icon"] {
    margin: 0;
    margin-left: 1rem;

    & > svg {
      fill: #FFD79D;
    }

  }
}

@mixin tableHead {
  color: #121212;
  font-weight: bold;
}

.commonIndexTable {
  [class~="Polaris-IndexTable__TableHeading"] {
    @include tableHead;
  }
}

.commonDataTable {
  thead th {
    border-bottom-color: rgba(225, 227, 229, 1);
  }
  [class ~= 'Polaris-DataTable__Table'] {
    thead th {
      @include tableHead;
    }
    tbody th,
    tbody td {
      color: #6F6F6F;
      max-width: 30rem;
      white-space: normal;
    }
  }
}
