.cardContainer{

  margin-top: 2rem;

  & li::marker {
    content: url("./checkIcon.svg");
  }

  [class~="Polaris-List__Item"]{
    padding-inline-start: 0.7rem;
  }
  [class~="Polaris-Card__Section"]+[class~="Polaris-Card__Section"]{
    padding-top: 0;
    border-top: none;
  }

  & *.price {
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    font-size: 2rem;
    display: inline-block;
  }
}

.plansWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  [class~="Polaris-Card"]{
    min-width: 35rem;
    max-width: 65rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  [class~="Polaris-Card__Footer"]{
    margin-top: auto;
  }

  & > *.cardContainer:nth-child(1){
    margin-right: 2rem;
    flex-grow: 0.3;
  }

}

.priceLink {
  $linkColor: #107F61;

  display: flex;
  align-items: center;
  color: $linkColor;
  & [class~="Polaris-Icon"]{
    display: inline-block;
    width: 1.5rem;
    height: inherit;
    margin: 0;
    * {
      fill: $linkColor;
    }
  }
}

